<template>
  <div class="exchange-transaction-details-container wrapper">
    <CRow class>
      <CCol lg="12" md="12">
        <CCard>
          <CCardHeader>
            <router-link :to="exchangeTransactionListUrl">
              <CIcon name="cil-arrow-left" class="mr-1" />
              {{ $t('EXCHANGE_TRANSACTION_DETAILS_PAGE_BACK_LINK_BUTTON') }}
            </router-link>
          </CCardHeader>
          <CCardBody v-if="isLoading">
            <vcl-list></vcl-list>
          </CCardBody>
          <CCardBody v-if="!isLoading">
            <CRow>
              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('EXCHANGE_TRANSACTION_DETAILS_PAGE_TRANSACTION_ID_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext"
                  ><a :href="changellyTrackUrl + exchangeTransactionDetails.transaction_id" target="_blank" rel="noopener noreferrer">{{
                    exchangeTransactionDetails.transaction_id
                  }}</a></span
                >
              </CCol>
              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('EXCHANGE_TRANSACTION_DETAILS_PAGE_STATUS_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                <div class="form-control-plaintext">
                  <StakingBadge type="exchange-transaction-status" :text="exchangeTransactionDetails.status" />
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('EXCHANGE_TRANSACTION_DETAILS_PAGE_EMAIL_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext">{{ exchangeTransactionDetails.email }}</span>
              </CCol>
              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('EXCHANGE_TRANSACTION_DETAILS_PAGE_TRANSACTION_DATE_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                <div class="form-control-plaintext">
                  <span>{{ exchangeTransactionDetails.transaction_date | moment(dateFormat) }}</span>
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('EXCHANGE_TRANSACTION_DETAILS_PAGE_FROM_CURRENCY_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                <div class="form-control-plaintext">
                  <span>{{ exchangeTransactionDetails.from_currency }}</span>
                </div>
              </CCol>

              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('EXCHANGE_TRANSACTION_DETAILS_PAGE_TO_CURRENCY_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                <div class="form-control-plaintext">
                  <span>{{ exchangeTransactionDetails.to_currency }}</span>
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('EXCHANGE_TRANSACTION_DETAILS_PAGE_REQUEST_REFUND_ADDRESS_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                <div class="form-control-plaintext">
                  <StakingExplorerLink
                    type="address"
                    :currencySymbol="exchangeTransactionDetails.from_currency"
                    :value="exchangeTransactionDetails.request_refund_address"
                  />
                </div>
              </CCol>
              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('EXCHANGE_TRANSACTION_DETAILS_PAGE_REQUEST_RECIPIENT_ADDRESS_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                <div class="form-control-plaintext">
                  <StakingExplorerLink
                    type="address"
                    :currencySymbol="exchangeTransactionDetails.to_currency"
                    :value="exchangeTransactionDetails.request_recipient_address"
                  />
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('EXCHANGE_TRANSACTION_DETAILS_PAGE_TX_ID_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                <div class="form-control-plaintext">
                  <StakingExplorerLink
                    type="tx"
                    :currencySymbol="exchangeTransactionDetails.from_currency"
                    :value="exchangeTransactionDetails.tx_id"
                  />
                </div>
              </CCol>
              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('EXCHANGE_TRANSACTION_DETAILS_PAGE_PAYOUT_TX_ID_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                <div class="form-control-plaintext" v-if="exchangeTransactionDetails.status == 'REFUNDED'">
                  <StakingExplorerLink
                    type="tx"
                    :currencySymbol="exchangeTransactionDetails.from_currency"
                    :value="exchangeTransactionDetails.payout_tx_id"
                  />
                </div>
                <div class="form-control-plaintext" v-else>
                  <StakingExplorerLink
                    type="tx"
                    :currencySymbol="exchangeTransactionDetails.to_currency"
                    :value="exchangeTransactionDetails.payout_tx_id"
                  />
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('EXCHANGE_TRANSACTION_DETAILS_PAGE_PROVIDER_FEE_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext">{{ exchangeTransactionDetails.provider_fee }}%</span>
              </CCol>
              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('EXCHANGE_TRANSACTION_DETAILS_PAGE_API_EXTRA_FEE_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext">{{ exchangeTransactionDetails.api_extra_fee }}%</span>
              </CCol>
            </CRow>

            <CRow>
              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('EXCHANGE_TRANSACTION_DETAILS_PAGE_NETWORK_FEE_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext">{{ exchangeTransactionDetails.network_fee }}%</span>
              </CCol>
              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('EXCHANGE_TRANSACTION_DETAILS_PAGE_TOTAL_FEE_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext">{{ exchangeTransactionDetails.total_fee }}%</span>
              </CCol>
            </CRow>

            <CRow>
              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('EXCHANGE_TRANSACTION_DETAILS_PAGE_AMOUNT_EXPECTED_FROM_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext"
                  >{{ exchangeTransactionDetails.amount_expected_from }} {{ exchangeTransactionDetails.from_currency }}</span
                >
              </CCol>
              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('EXCHANGE_TRANSACTION_DETAILS_PAGE_AMOUNT_EXPECTED_TO_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext"
                  >{{ exchangeTransactionDetails.amount_expected_to }} {{ exchangeTransactionDetails.to_currency }}</span
                >
              </CCol>
            </CRow>

            <CRow>
              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('EXCHANGE_TRANSACTION_DETAILS_PAGE_AMOUNT_FROM_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext">{{ exchangeTransactionDetails.amount_from }}</span>
              </CCol>
              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('EXCHANGE_TRANSACTION_DETAILS_PAGE_AMOUNT_TO_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext" v-if="exchangeTransactionDetails.status == 'REFUNDED'"
                  >{{ exchangeTransactionDetails.amount_to }} {{ exchangeTransactionDetails.from_currency }}</span
                >
                <span class="form-control-plaintext" v-else
                  >{{ exchangeTransactionDetails.amount_to }} {{ exchangeTransactionDetails.to_currency }}</span
                >
              </CCol>
            </CRow>

            <CRow>
              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('EXCHANGE_TRANSACTION_DETAILS_PAGE_PROVIDER_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext">{{ exchangeTransactionDetails.provider }}</span>
              </CCol>
              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('EXCHANGE_TRANSACTION_DETAILS_PAGE_RATE_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext">{{ exchangeTransactionDetails.rate }}</span>
              </CCol>
            </CRow>

            <CRow>
              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('EXCHANGE_TRANSACTION_DETAILS_PAGE_PAYIN_ADDRESS_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                <div class="form-control-plaintext">
                  <StakingExplorerLink
                    type="address"
                    :currencySymbol="exchangeTransactionDetails.from_currency"
                    :value="exchangeTransactionDetails.payin_address"
                  />
                </div>
              </CCol>
              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('EXCHANGE_TRANSACTION_DETAILS_PAGE_PAYOUT_ADDRESS_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                <div class="form-control-plaintext">
                  <StakingExplorerLink
                    type="address"
                    :currencySymbol="exchangeTransactionDetails.to_currency"
                    :value="exchangeTransactionDetails.payout_address"
                  />
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('EXCHANGE_TRANSACTION_DETAILS_PAGE_EXTRA_ID_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext">{{ exchangeTransactionDetails.request_extra_id }}</span>
              </CCol>
              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('EXCHANGE_TRANSACTION_DETAILS_PAGE_REFUND_EXTRA_ID_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext">{{ exchangeTransactionDetails.request_refund_extra_id }}</span>
              </CCol>
            </CRow>

            <CRow>
              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('EXCHANGE_TRANSACTION_DETAILS_PAGE_PAYIN_EXTRA_ID_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext">{{ exchangeTransactionDetails.request_extra_id }}</span>
              </CCol>
              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('EXCHANGE_TRANSACTION_DETAILS_PAGE_PAYOUT_EXTRA_ID_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext">{{ exchangeTransactionDetails.request_refund_extra_id }}</span>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import { VclList } from 'vue-content-loading';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';
import stringHelper from '@/utils/string-helper';
import currencyHelper from '@/utils/currency-helper';
import { Constants, env } from '@/constants';
import StakingBadge from '@/components/StakingBadge';
import StakingExplorerLink from '@/components/StakingExplorerLink';

export default {
  name: 'EditExchangeTransaction',
  components: {
    VclList,
    StakingBadge,
    StakingExplorerLink,
  },
  data() {
    return {
      changellyTrackUrl: env.changellyTrackUrl,
      dateFormat: Constants.dateFormat,
      exchangeTransactionId: null,
      exchangeTransactionDetails: null,
      exchangeTransactionListUrl: '/admin/exchange/transactions',
      isLoading: true,
    };
  },
  async mounted() {
    this.exchangeTransactionId = this.$route.params.transactionId;
    await Promise.all([this.getExchangeTransactionDetails(this.exchangeTransactionId)]);
    this.isLoading = false;
  },
  methods: {
    backToExchangeTransactionList() {
      this.$router.push(this.exchangeTransactionListUrl);
    },

    async getExchangeTransactionDetails(exchangeTransactionId) {
      try {
        const res = await this.$http.get(endpoints.getExchangeTransactionDetail(this.exchangeTransactionId));
        this.exchangeTransactionDetails = res.data;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('EXCHANGE_TRANSACTION_DETAILS_PAGE_NOTIFICATION_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
  },
};
</script>

<style lang="scss">
.exchange-transaction-details-container {
  &.wrapper {
    padding: 0 24px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #695fe5;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
        cursor: pointer;
      }
    }

    .currency-icon {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
